<template>
<!-- zx 2021 5 27 -->
<div> 
  <!-- <div class="conforms1" v-lazy:background-image="require('./paid_img/banner.png')"> -->
   <div class="conforms1">
    <div class="start">
   <img v-lazy ="require('./paid_img/AaX2Ocn1.webp')" class="start-img">
 </div>
  <div class="bannert">
      <div class="text1" >薪福通</div>
    <div class="text2" >薪酬福利一站式数字金融服务</div>
  </div>
  </div>
  <div class="conforms2">
    <div class="text3">一站式服务&nbsp;助力企业快速转型</div>
    <!-- <div class="conforms2_1"  v-lazy:background-image="require('./paid_img/1.png')"> -->
    <div class="conforms2_1" >
       <div class="img-1">
   <img v-lazy ="require('./paid_img/RkSvkT4M.webp')" class="img-2">
 </div>
      <div class="literal">
        <div class="text4">薪福通2.0</div>
      <div class="text5" >薪酬福利一站式数字金融服务</div>
      <div  class="text6">
        薪福通整合薪酬代发、人事、财务、团体福利等功能于一体，
                  <br />
                  帮助企业高效准确实现员工入职、定薪、算薪、算税、发薪、
                  <br />
                  发工资单、发福利一站式全线上流程，让人事财务更轻松。</div>
    </div>
      </div>

  </div>
  <div class="conforms3">
    <div class="text9">企业权益</div>
    <div class="text10">
      <div class="text8">
        <img src="./paid_img/2.png" class="pic_1">
        <div>
          <div class="text11">代发工资</div>
          <div class="text7 mt16">
            <img src="./paid_img/（1）.png" class="circle">
            <span class="text12" >线上算薪算税发薪一体化服务</span>
          </div>
          <div class="text7 mt8">
            <img src="./paid_img/（1）.png" class="circle">
            <span class="text12" >预约代发、三方联动代发、集团直连代发</span>
          </div>
          <div class="text7 mt8">
            <img src="./paid_img/（1）.png" class="circle">
            <span class="text12">员工入职工资卡开户申请、卡号收集线上完成</span>
          </div>
        </div>
      </div>
        <div class="text8 ml30">
        <img src="./paid_img/4.png" class="pic_1">
        <div>
          <div class="text11">人事服务</div>
          <div class="text7 mt16">
             <img src="./paid_img/（1）.png" class="circle">
            <span class="text12">全线上化的员工管理流程</span>
          </div>
          <div class="text7 mt8">
             <img src="./paid_img/（1）.png" class="circle">
            <span class="text12">提供人事花名册、对接假勤管理、电子工资单</span>
          </div>
          <div class="text7 mt8">
             <img src="./paid_img/（1）.png" class="circle">
            <span class="text12">打通社保公积金计算，直连个税算报缴</span>
          </div>
        </div>
      </div>
    </div>
      <div class="text13 mt30">
      <div class="text8">
        <img src="./paid_img/3.png" class="pic_1">
        <div>
          <div class="text11">企业福利</div>
          <div class="text7 mt16">
            <img src="./paid_img/（1）.png" class="circle">
            <span class="text12">福利商城提供2000多个品类的优质品牌产品</span>
          </div>
          <div class="text7 mt8">
             <img src="./paid_img/（1）.png" class="circle">
            <span class="text12">对接泰康、信诺等知名企业团险服务商</span>
          </div>
          <div class="text7 mt8">
             <img src="./paid_img/（1）.png" class="circle">
            <span class="text12">提供可视频互动的企业红包</span>
          </div>
        </div>
      </div>
        <div class="text8 ml30">
        <img src="./paid_img/5.png" class="pic_1">
        <div>
          <div class="text11">财务服务</div>
          <div class="text7 mt16">
             <img src="./paid_img/（1）.png" class="circle">
            <span class="text12">财务管理系统，标准化财务记账系统</span>
          </div>
          <div class="text7 mt8">
             <img src="./paid_img/（1）.png" class="circle">
            <span class="text12">智能费控，企业全方位费用报销管理</span>
          </div>
          <div class="text7 mt8">
             <img src="./paid_img/（1）.png" class="circle">
            <span class="text12">发票云、收费云企业零成本线上化</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="conforms4">
    <div class="text14">员工权益</div>
    <div class="text13 mt60">
      <div class="text15">
        <div>
          <img src="./paid_img/8.png" class="pic_2 mt30">
          <div class="text16 ">专属卡片&nbsp;薪e卡</div>
          <div class="text17 mt16">
            招行代发客户专属工资卡，工本费、账户管理、ATM跨行取款手续费等均减免
          </div>
        </div>
      </div>
       <div class="text15 ml30">
        <div>
          <img src="./paid_img/9.png" class="pic_2 mt30">
          <div class="text16 ">专属权益</div>
          <div class="text17 mt16">
           登录招商银行APP薪福通专区，尊享9元购、9分兑好礼，还可领取开薪红包、招牌下午茶、好礼享停
          </div>
        </div>
      </div>
       <div class="text15 ml30">
        <div>
          <img src="./paid_img/7.png" class="pic_2 mt30">
          <div class="text16 ">专属产品</div>
          <div class="text17 mt16">
            尊享工资卡专属财富产品、边花边赚朝朝宝、收益稳健聚益生金理财，更有为工资卡量身制定的存款、定投、保险等产品
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="conforms5">
    <img src="./paid_img/6.png" class="pic_3">
    <img src="./paid_img/img.png" class="pic_4">
    <div class="text19">联合出品</div>

  </div> -->

<div>
  <Header></Header>
  <Footer></Footer>
  </div>
</div>
</template>
<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
components: {
    Header,
    Footer,
  },
}
</script>
<style lang="scss" scoped>
/* 动画效果 */
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.conforms1{
  height: 600px;
  background-repeat:  no-repeat;
  background-size: 100% 100%;
}
.text1{
  color: rgba(255, 255, 255, 1);
  font-size: 48px;
  text-align: left;
}
.start_img{
   height: 600px;
  width: 100%;
}
.start{
   height: 600px;
  width: 100%;
  display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 200px;
		height: 200px;
    

	}
	// 实际图片大小
	img[lazy="loaded"] {
		 height: 600px;
  width: 100%;
	}
}
.bannert{
   position: absolute;
    left: 361px;
    top: 230px;

}
.literal{
  position: absolute;
  top: 73px;
}
.text1{
    animation: sport 0.5s;
  transform: translateY(0px);
}
.text2{
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  text-align: left;
  margin-top: 20px;
}
.text2{
   animation: sport 0.5s;
  transform: translateY(0px);
}
.conforms2{
  height: 660px;
  margin: auto;

}
.text3{
   color: rgba(51, 51, 51, 1);
   font-size: 40px;
   padding-top: 80px;
}
.conforms2_1{
height: 364px;
width: 1198px;
  margin: auto;
  margin-top: 60px;
  position: relative;
  
}
.img-2{
  height: 364px;
width: 1198px;

}
.img-1{
   height: 364px;
width: 1198px;
display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 150px;
		height: 150px;
    

	}
	// 实际图片大小
	img[lazy="loaded"] {
		   height: 364px;
width: 1198px;
	}
}
.text4{
  color: rgba(255, 255, 255, 1);
  font-size: 48px;
  // padding-top: 73px;
  padding-left: 48px;
  text-align: left;
}
.text5{
  color: rgba(255, 255, 255, 0.9);
  font-size: 20px;
  padding-top: 8px;
  padding-left: 48px;
  text-align: left;
  width:273px;
}
.text6{
  color: rgba(255, 255, 255, 0.88);
  font-size: 20px;
  padding-top: 26px;
  padding-left: 48px;
  text-align: left;
  width: 567px;
}
.circle{
  height: 8px;
  width: 8px;
  margin-top: 7px;

}
.text7{
  display: flex;
  /* align-items: center; */
  margin-left: 36px;
  text-align: left;
  
}
.text8{
  height: 225px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
  width: 585px;
  display: flex;
  /* justify-self: center; */
  align-items: center;
  
}
.conforms3{
   color: rgba(51, 51, 51, 1);
  font-size: 40px;
  /* height: 776px; */
  background-color: #F6F8FB;
  padding-top: 80px;
  padding-bottom: 100px;
}
.text10{
  display: flex;
  justify-content: center;
  padding-top: 60px;
}
.pic_1{
  width: 96px;
  height: 88px;
  margin-left: 48px;
}
.text11{
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  text-align: left;
  padding-left: 36px;
}
.text12{
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  padding-left: 16px;
  text-align: left;
}
.mt16{
  margin-top: 16px;
}
.mt8{
  margin-top: 8px;
}
.ml30{
  margin-left: 30px;
}
.mt30{
  margin-top: 30px;
}
.text13{
  display: flex;
  justify-content: center;
}
.conforms4{
  margin: auto;
  margin-bottom: 100px;
}
.text14{
  color: rgba(51, 51, 51, 1);
  font-size: 40px;
  padding-top: 80px;
}
.pic_2{
  height: 108px;
  width: 108px;
}
.text15{
  display: flex;
  justify-content: center;
  height: 406px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
  width: 380px;
}
.text16{
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
}
.text17{
  color: rgba(153, 153, 153, 1);
  font-size: 20px;
  text-align: left;
  width: 270px;
  letter-spacing: 0.5px;
  line-height: 32px;
}
.mt60{
  margin-top: 60px;
}
.conforms5{
  height: 248px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text19{
  color: rgba(51, 51, 51, 1);
  font-size: 32px;
  height: 45px;
  line-height: 45px;
}
.pic_3{
width: 247px;
height: 48px;
}
.pic_4{
  width: 147px;
  height: 60px;
}
</style>